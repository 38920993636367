<template>
  <div class="">
    <div class="md-layout">
      <div class="md-layout-item">
        <h2>ACCESO A LOS SISTEMAS</h2>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item" v-for="(s,idx) in sistemas" :key="idx">

        <span @click="ir(s.sistema)">
          <md-card  class="md-primary zoom" :style="`cursor:pointer;background-color:${s.fondo};margin:4em;border-radius:1em;`">
          <md-card-header>
            <md-card-header-text>
              <div class="md-display-1" :style="`color:${s.letra};`">{{s.nombre}}</div>
              <!-- <div class="md-subhead">Subtitle here</div> -->
            </md-card-header-text>

            <!-- <md-card-media>
              <img src="/vuematerial/assets/examples/avatar-2.jpg" alt="Avatar">
            </md-card-media> -->
          </md-card-header>

          <md-card-actions>
            <button class="btn btn-2" @click="ir(s.sistema)"><md-icon>home</md-icon> INGRESAR</button>
          </md-card-actions>
        </md-card>
        </span>


        
      </div>
    </div>
    
  </div>
</template>

<script>
//import {state} from 'vuex'
export default {
  name: '',
  data:()=>({
    sistemas:[
      {nombre:'ROMANA V2',sistema:'romana',fondo:'#1f196e',letra:'#ffffff'},
      {nombre:'ROMANA V1',sistema:'plp-ori',fondo:'#327da8',letra:'#ffffff'},
      {nombre:'PRODUCCIÓN',sistema:'produccion',fondo:'#1a5a80',letra:'#ffffff'},
      {nombre:'PORTERÍA',sistema:'porteria',fondo:'#3097d1',letra:'#ffffff'},
      {nombre:'CONTROL DE BINS',sistema:'bins',fondo:'#ff9800',letra:'#ffffff'},
    ]
  }),
  methods:{
    ir(sistema){
      if(sistema == 'romana'){
        window.open(`${this.$store.state.ip}${this.$store.state.link_romana}`, '_blank');
      }

      if(sistema == 'produccion'){
        window.open(`${this.$store.state.ip}${this.$store.state.link_produccion}`, '_blank');
      }

      if(sistema == 'bins'){
        window.open(`${this.$store.state.ip}${this.$store.state.link_bins}`, '_blank');
      }

      if(sistema == 'porteria'){
        window.open(`${this.$store.state.ip}${this.$store.state.link_porteria}`, '_blank');
      }

      if(sistema == 'plp-ori'){
        window.open(`${this.$store.state.ip}${this.$store.state.link_plp_ori}`, '_blank');
      }
      console.log(sistema);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.btn{
  cursor:pointer;
  padding: 0.5em;
  min-width: 150px;
}
.btn:hover{
  background-color:rgba(255, 255, 255, 0.7)
}

.btn-1{
  background-color:#61a5c2;
  color:#fff;
  border-radius:1em;
  border:none;
}

.zoom{
  transition: transform .2s;
}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.btn-2{
  background-color:#ffbd00;
  color:#000;
  border-radius:1em;
  border:none;
}
.btn-3{
  background-color:#61a5c2;
  color:#fff;
  border-radius:1em;
  border:none;
}
</style>
