import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(Vuex)


Vue.use(VueMaterial)
Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    link_romana: '/romanav2',
    link_plp_ori:'/plp-ori',
    link_produccion: '/sistema-produccion',
    link_bins: '/control-bins',
    link_porteria: '/porteria/public',
    ip:''
  },
  mutations: {
    actualizar_ip(state,payload){
      state.ip = payload;
    }
  },
  plugins: [createPersistedState()],
})

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
