<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo_avanze.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  mounted(){
    console.log(window.location.href);
    let ip = window.location.href;
    if(ip.includes('localhost')){
      this.$store.commit('actualizar_ip','https://190.13.184.67');
    }else if(ip.includes('190.13.184.67')){
      this.$store.commit('actualizar_ip','https://190.13.184.67');
    }else if(ip.includes('192.168.205.150')){
      this.$store.commit('actualizar_ip','https://192.168.205.150');
    }
  },
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
